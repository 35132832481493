.schedule-an-interview {
  background: url(../../assets/schedule-interview-header-bg.png) no-repeat;
  background-size: 100%;
  height: 100vh;
}

.schedule-an-interview .header {
  padding-top: 0.853333rem;
  padding-left: 0.8rem;
  padding-bottom: 0.293333rem;
}

.schedule-an-interview .header .title,
.schedule-an-interview .header .subtitle {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
}

.schedule-an-interview .header .title {
  height: 0.8rem;
  font-size: 0.586667rem;
  font-weight: 600;
  line-height: 0.8rem;
}

.schedule-an-interview .header .subtitle {
  margin-top: 0.186667rem;
  font-size: 0.4rem;
  font-weight: 400;
  line-height: 0.56rem;
}

.schedule-an-interview .body .card {
  margin: 0 0.533333rem;
  background: #ffffff;
  box-shadow: 0 0 0.106667rem 0 rgba(141,141,141,0.5);
  border-radius: 0.266667rem;
  padding: 0.64rem 0.586667rem 0.613333rem 0.586667rem;
}

.schedule-an-interview .body .card .title {
  height: 0.666667rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.666667rem;
}

.schedule-an-interview .body .card .form {
  padding-top: 0.48rem;
}

.schedule-an-interview .body .card .form .form-row+.form-row {
  padding-top: 0.106667rem;
}

.schedule-an-interview .body .card .form .form-row {
  display: flex;
}

.schedule-an-interview .body .card .form .form-row .form-label,
.schedule-an-interview .body .card .form .form-row .form-content {
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 0.56rem;
  font-weight: 400;
}

.schedule-an-interview .body .card .form .form-row .form-label {
  min-width: 2.6rem;
  margin-right: 0.266667rem;
  color: #979797;
}

.schedule-an-interview .body .card .form .form-row .form-content {
  color: #333333;
}

.schedule-an-interview .body .select-date {
  padding: 1.226667rem 0.8rem 0.64rem 0.8rem;
}

.schedule-an-interview .body .select-date .title {
  font-size: 0.48rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.666667rem;
  padding-bottom: 0.4rem;
}

.schedule-an-interview .body .select-date .select-button {
  margin-left: -0.24rem;
}

.schedule-an-interview .body .select-date .select-button .active {
  background: #1890ff;
  color: #ffffff;
  border-color: #1890ff;
}

.schedule-an-interview .body .select-date .select-button .button {
  margin-left: 0.24rem;
  margin-right: 0.24rem;
  margin-bottom: 0.48rem;
  border-radius: 0.133333rem;
  border: 0.026667rem solid #dddddd;
  padding: 0.213333rem 0.48rem;
  border-radius: 0.133333rem;
  display: inline-block;
  text-align: center;
  font-size: 0.373333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}

.schedule-an-interview .body .select-date .select-button .button span {
  display: block;
}

.schedule-an-interview .body .m-divider {
  height: 0.373333rem;
  background: #f5f5f5;
}

.schedule-an-interview .body .select-time {
  padding: 0.666667rem 0.8rem;
}

.schedule-an-interview .body .select-time .afternoon {
  padding-top: 0.186667rem;
}

.schedule-an-interview .body .select-time .morning .title,
.schedule-an-interview .body .select-time .afternoon .title {
  padding-bottom: 0.373333rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.666667rem;
}

.schedule-an-interview .body .select-time .morning .select-button,
.schedule-an-interview .body .select-time .afternoon .select-button {
  margin-left: -0.24rem;
}

.schedule-an-interview .body .select-time .morning .select-button .button,
.schedule-an-interview .body .select-time .afternoon .select-button .button {
  margin-left: 0.24rem;
  margin-right: 0.24rem;
  margin-bottom: 0.48rem;
  padding: 0.213333rem 0.186667rem 0.186667rem 0.373333rem;
  height: 1.546667rem;
  border-radius: 0.133333rem;
  border: 0.026667rem solid #dddddd;
  display: inline-block;
}

.schedule-an-interview .body .select-time .morning .select-button .button span,
.schedule-an-interview .body .select-time .afternoon .select-button .button span {
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.533333rem;
}

.schedule-an-interview .body .select-time .morning .select-button .button span:first-child,
.schedule-an-interview .body .select-time .afternoon .select-button .button span:first-child {
  color: #333333;
  display: block;
}

.schedule-an-interview .body .select-time .morning .select-button .button span:nth-child(2),
.schedule-an-interview .body .select-time .afternoon .select-button .button span:nth-child(2) {
  color: #979797;
}

.schedule-an-interview .body .select-time .morning .select-button .disabled,
.schedule-an-interview .body .select-time .afternoon .select-button .disabled {
  background: #eaeaea;
  border: 0.026667rem solid #dddddd;
}

.schedule-an-interview .body .select-time .morning .select-button .disabled span:first-child,
.schedule-an-interview .body .select-time .afternoon .select-button .disabled span:first-child {
  color: #979797;
}

.schedule-an-interview .body .select-time .morning .select-button .active,
.schedule-an-interview .body .select-time .afternoon .select-button .active {
  border: 0.04rem solid #1890ff;
}

.schedule-an-interview .body .select-time .morning .select-button .active .right,
.schedule-an-interview .body .select-time .afternoon .select-button .active .right {
  position: relative;
}

.schedule-an-interview .body .select-time .morning .select-button .active .right::after,
.schedule-an-interview .body .select-time .afternoon .select-button .active .right::after {
  opacity: 1;
  content: '';
  position: absolute;
  width: 0.213333rem;
  height: 0.4rem;
  background: transparent;
  border: 0.08rem solid #1890ff;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 0.133333rem;
  top: 0.16rem;
  right: -0.16rem;
  transform: rotate(39deg);
  -webkit-transform: rotate(39deg);
  -moz-transform: rotate(39deg);
  -o-transform: rotate(39deg);
  -ms-transform: rotate(39deg);
  transform: rotate(39deg);
}

.schedule-an-interview .footer {
  height: 1.893333rem;
}

.schedule-an-interview .footer .van-submit-bar {
  background: #ffffff;
  box-shadow: 0 -0.053333rem 0.106667rem 0 rgba(103,116,140,0.1);
  height: 1.893333rem;
}

.schedule-an-interview .footer .van-submit-bar__bar {
  height: 1.546667rem;
  margin-bottom: 0.346667rem;
  padding: 0.24rem 0.533333rem;
}

.schedule-an-interview .footer .feedback-tip {
  height: 1.066667rem;
  border-radius: 0.56rem;
  border: 0.026667rem solid #dddddd;
  padding: 0 0.266667rem;
  line-height: 1.066667rem;
  text-align: center;
  margin-right: 0.426667rem;
}

.schedule-an-interview .footer .van-button {
  height: 1.066667rem;
  background: #4f8fff;
  border-radius: 0.56rem;
}

.schedule-an-interview .footer .van-button .van-button__text {
  width: auto;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.56rem;
}

.schedule-an-interview .feedback-text {
  padding: 0.88rem 0.533333rem 1.093333rem 0.533333rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.666667rem;
}

.interview-empty {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.interview-empty img {
  width: 6.666667rem;
}